import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Signature Pub Group Customer Story - Rotaready"
          description="Since implementing Rotaready, Signature Pub Group has been able to better deploy over 93 hours every week and achieved an ROI of over 600%."
          url="customer-stories/hospitality-signature-pub-group"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Signature Pubs cocktails"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    style={{ height: '50px' }}
                    alt="Signature Pubs logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Since implementing Rotaready, Signature Pub Group has been able to better deploy over 93 hours every week and achieved an ROI of over 600%." />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="24" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="650" />
              <StatSubtitle text="staff" />
            </StatItem>

            <StatItem>
              <StatHeadline text="612%" />
              <StatSubtitle text="ROI" />
            </StatItem>

            <StatItem>
              <StatHeadline text="93 hours" />
              <StatSubtitle text="better deployed each week" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="Signature Pub Group" />
              </Content>

              <Content>
                <TextBlock text="Launched in 2003 by Nic Wood, Signature Pub Group's very first venue was the Grassmarket's Black Bull. Since then, the Edinburgh based pub group has opened a further 23 bars, restaurants and hotels across Scotland. Each venue unique in its delivery, offering, and for a time, approach to staff scheduling." />
              </Content>

              <Content>
                <TextBlock text="For almost 15 years, every site had its own way of scheduling staff rotas and monitoring time and attendance. Some were using Excel and others pen and paper, making it almost impossible to accurately schedule and track staff time. Not only was this approach vastly time consuming for managers, it left head office with very limited visibility over staff hours, wage costs and commercial performance." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="The search for the perfect partner" />
              </Content>

              <Content>
                <TextBlock text="So in January 2017, the business decided it was time to find a solution that could help streamline staff scheduling across the business." />
              </Content>

              <Content>
                <TextBlock text="Signature Pub Group wanted something that would not only make building staff rotas easier, and give head office greater visibility over costs, but one that would help them with HR and absence management, too. A central hub for important employee documents and a way of automatically ensuring they were compliant with legislation, such as the Working Time Regulations." />
              </Content>

              <Content>
                <TextBlock text="After an extensive search and reviewing a number of different staff scheduling platforms, Signature Pub Group turned to Rotaready to help take its workforce management to the next level." />
              </Content>

              <Content>
                <Blockquote
                  text="Yes, Rotaready ticked all of the boxes in terms of functionality, but the nature of the business and enthusiasm of the team really stood out to us. It was never about just making another sale, it was about providing us with the best solution for our business."
                  attribution="Rory Forrest, Financial Director"
                />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="A streamlined approach to staff scheduling" />
              </Content>
            </Section>

            <Section>
              <PopQuote text="After trialling the rota software at one of its sites, Signature Pub Group opted for a staggered approach to implementing Rotaready; rolling out across the 13 remaining venues over a period of 3 months. The group has since opened and implemented Rotaready at a further 10 sites." />
            </Section>

            <Section>
              <Content>
                <Blockquote
                  text="For us, a phased roll out was definitely the way to go. The team at Rotaready were on hand to support us throughout the implementation process, and have been ever since!"
                  attribution="Rory Forrest, Financial Director"
                />
              </Content>

              <Content>
                <TextBlock text="Implementing Rotaready has been a real game changer for the entire business. General Managers can easily build and share optimised staff rotas that are always compliant with business requirements and Working Time Regulations. Head Office have greater visibility and control over wage spend against revenues, plus a secure and accessible place to store important or time-sensitive employee documents. And employees can view upcoming shifts and request time off, via the Rotaready app." />
              </Content>

              <Content>
                <Blockquote
                  text="Previously we would have only found out about any labour overspend at the end of each week. Now we have real-time visibility of this, which has enabled us to optimise wage spend in line with revenues and demand."
                  attribution="Rory Forrest, Financial Director"
                />
              </Content>
            </Section>

            <Section>
              <PopQuote text="A combination of all of these factors has resulted in both time and cost savings for Signature Pub Group. The business has been able to better deploy over 93 hours every week, as a direct result of using Rotaready and has achieved a return on investment of over 620%." />
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline1.childImageSharp.gatsbyImageData}
                alt="Pub interior"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="What's next?" />
              </Content>

              <Content>
                <TextBlock text="Rotaready's customer success team are continuing to work closely with Signature Pub Group to ensure they're leveraging all the benefits of the platform, while feeding back to the engineering team to ensure the product remains as customer-centric as possible." />
              </Content>

              <Content>
                <TextBlock text="Next on the agenda is to implement Rotaready's tronc feature across the business. This will enable the team to automatically calculate tronc and ensure accurate tronc payments at the same time as regular pay runs." />
              </Content>

              <Content>
                <Blockquote
                  text="Rotaready has been a great success for our business. It has continued to develop since we first implemented it in 2017, regularly introducing new and useful features which further enhance its value. The platform itself is robust, reliable and user-friendly and the team behind it are great. It's safe to say that I'd highly recommend Rotaready as a staff scheduling partner."
                  attribution="Rory Forrest, Financial Director"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline2.childImageSharp.gatsbyImageData}
                alt="Steak, sauce and garnish"
              />
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_signaturepubs"
                label="rotaready.com/customer-stories/hospitality-signature-pub-group"
                postTo="marketing"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Signature-Pub-Group.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/header-signaturepubs.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/logo-signaturepubs.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 50, quality: 100)
      }
    }

    inline1: file(relativePath: { eq: "customer-stories/inline-signaturepubs.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    inline2: file(relativePath: { eq: "customer-stories/inline-signaturepubs2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
